export {}

const mainHeader = document.querySelector(".m-header")
const notifications = document.querySelectorAll(".i-notification")
const headerSearches = document.querySelectorAll(".m-header-search")
const mainOverlay = document.querySelector(".c-overlay-main")
const openers = document.querySelectorAll(".--js--main-search-opener")
const closers = document.querySelectorAll(".--js-main-search-closer")

headerSearches.forEach((headerSearch) => {
  const searchForm = headerSearch.querySelector<HTMLElement>(".m-header-search__form")
  let headerHeight = mainHeader?.clientHeight ?? 0;

  if (!searchForm) return;

  notifications.forEach((notification) => {
    let notificationHeight = notification.clientHeight ?? 0;

    const resize = () => {
      const resizeObserver = new ResizeObserver(() => {
        headerHeight = mainHeader?.clientHeight ?? 0;
        notificationHeight = notification.clientHeight ?? 0;

        searchForm.style.height = `${headerHeight + notificationHeight}px`;
        searchForm.style.setProperty("--translate-y", `${-notificationHeight}px`)
      })

      resizeObserver.observe(document.body)
    }

    const scroll = () => {
      window.addEventListener("scroll", () => {
        if (mainHeader?.classList.contains("headroom--not-top")) {
          searchForm.style.setProperty("--translate-y", '0px')
        } else {
          searchForm.style.setProperty("--translate-y", `${-notificationHeight}px`)
        }
      })
    }

    if (notification) {
      searchForm.style.height = `${headerHeight + notificationHeight}px`;
      searchForm.style.setProperty("--translate-y", `${-notificationHeight}px`)

      scroll()
      resize()
    }
  })

  const open = () => {
    headerSearch.classList.add("--open")
    mainOverlay?.classList.add("--show")



  }
  const close = () => {
    headerSearch.classList.remove("--open")
    mainOverlay?.classList.remove("--show")

  }

  openers.forEach((opener) => opener.addEventListener("click", open))
  closers.forEach((closer) => closer.addEventListener("click", close))
})
