import Headroom from "headroom.js"

const mainHeader = document.querySelector(".m-header")
let offset = mainHeader?.clientHeight ?? 0

export const headRoomHeaderOptions: Headroom.HeadroomOptions = {
  offset: offset,
}

export const headRoomHeader = mainHeader ? new Headroom(mainHeader, headRoomHeaderOptions) : null

if (headRoomHeader) headRoomHeader.init()

window.addEventListener("scrollend", () => {
  setTimeout(() => {
    headRoomHeader?.unfreeze()
  })
})

export const checkHeaderHeight = () => {
  const notification = document.querySelector(".i-notification")

  if (notification) {
    const headerHeight = mainHeader?.clientHeight ?? 0
    let notificationHeight = notification?.clientHeight ?? 0
    const notificationStyle = window.getComputedStyle(notification);

    notificationHeight += parseInt(notificationStyle.marginTop);
    offset = headerHeight + notificationHeight

  } else {
    offset = mainHeader?.clientHeight ?? 0
  }

  const headRoomHeaderOptionsUpdate: Headroom.HeadroomOptions = {
    offset: offset,
  }

  const headRoomHeaderUpdate = mainHeader ? new Headroom(mainHeader, headRoomHeaderOptionsUpdate) : null
  if (headRoomHeaderUpdate) headRoomHeaderUpdate.init()
}

window.onload = () => {
  setTimeout(() => {
    checkHeaderHeight()
  }, 1500);
}

let windowWidth = window.innerWidth
window.addEventListener("resize", () => {

  if (window.innerWidth != windowWidth) {
    windowWidth = window.innerWidth
    checkHeaderHeight()
  }
});
